import React, { useState } from "react";
import styled, { css } from "styled-components";
import remark from "remark";
import reactRenderer from "remark-react";
import get from "lodash/get";

import Button from "./Button";

const Content = styled.div`
  word-wrap: break-word;
  margin-bottom: ${props => props.theme.space.single.m};

  ${props =>
    !props.showAll &&
    css`
      p:not(:first-child) {
        display: none;
      }
    `}

  p + p {
    margin-top: 1rem;
  }
`;

const Markdown = ({ className, markdown, showAll = true }) => {
  const text = remark()
    .use(reactRenderer)
    .processSync(markdown).contents;
  const descriptionLength = get(text, "props.children", []).filter(
    row => row.type && row.type === "p"
  ).length;
  const [showAllState, setShowAll] = useState(
    descriptionLength > 1 ? showAll : true
  );

  return (
    <Content className={className} showAll={showAllState}>
      {text}
      {!showAll && descriptionLength > 1 && (
        <Button type="button" onClick={() => setShowAll(!showAllState)}>
          {showAllState ? "Show less" : "Show full description"}
        </Button>
      )}
    </Content>
  );
};

export default styled(Markdown)``;
