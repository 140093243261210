import React from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import Section from "../components/layout/Section";
import Header from "../components/layout/Header";
import SelectNewsSite from "../components/news-site/SelectNewsSite";
import NewsSiteList from "../components/news-site/NewsSiteList";
import Relation from "../components/relation/Relation";

const StyledSelectNewsSite = styled(SelectNewsSite)`
  flex: 1;
  margin-left: 2rem;
  max-width: 400px;
`;

const RelationPage = ({ pageContext }) => {
  const { newsSites, relationType } = pageContext;
  const { uuid } = relationType;
  const relatedNewsSites = newsSites.filter(site =>
    site.relationType.find(r => r.uuid === uuid)
  );

  return (
    <Layout title={relationType.name}>
      <Section minimal>
        <Header>
          <StyledSelectNewsSite newsSites={newsSites} />
        </Header>
      </Section>
      <Section>
        <Relation relationType={relationType} />
      </Section>
      <Section dark>
        <h2>News sites with relations</h2>
        <NewsSiteList newsSites={relatedNewsSites} />
      </Section>
    </Layout>
  );
};

export default RelationPage;
