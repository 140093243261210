import React from "react";
import styled from "styled-components";

import Markdown from "../common/Markdown";

const Content = styled.div``;

const Relation = ({ className, relationType }) => {
  return (
    <Content className={className}>
      <h1>{relationType.name}</h1>
      <Markdown markdown={relationType.description} />
    </Content>
  );
};

export default styled(React.memo(Relation))``;
